import $ from 'jquery';


// jquery match height NMP
import jqueryMatchHeight from 'jquery-match-height';

// import fancyBox from './modules/fancybox';

import general from './modules/general';
import frontpage from './modules/frontpage';
import gsap from './modules/gsap';

import accordion from './modules/accordion';

// Bootsrap
import tabs from 'bootstrap/js/dist/tab';
import modal from 'bootstrap/js/dist/modal';
import collapse from 'bootstrap/js/dist/collapse';

// import 'bootstrap/js/dist/scrollspy';
import affix from 'bootstrap-affix/assets/js/affix';

document.addEventListener('DOMContentLoaded', function () {

	general.init();
	frontpage.init();
	gsap.init();

}, false);