import $ from 'jquery';

export default {
	init() {


		$('.match-height .block-grid__image').matchHeight();
		$('.match-height .block-grid__title').matchHeight();
		$('.filter-button-group span').matchHeight();
		
		


		// Team Block
		$('.acf-block-team h3').matchHeight();
		$('.acf-block-team .position').matchHeight();


		// bind change event to select
		$('#cat').on('change', function () {
			this.form.submit();
		});
		
	},
};
