import $ from 'jquery';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
/* import { DrawSVGPlugin } from "gsap/DrawSVGPlugin.js";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js"; */
import { CSSRulePlugin } from "gsap/CSSRulePlugin.js";
import ScrollReveal from 'scrollreveal';

export default {
	init() {
		"use strict";

		ScrollReveal({ mobile: false, cleanup: true });

		gsap.registerPlugin(ScrollTrigger);

		// Home - What We Do
		(function () {

			let section = document.querySelector(".acf-block-what-we-do");

			if (section === null) {
				return;
			}

			var circle = CSSRulePlugin.getRule(".what-we-do-case-studies:before"); 

			
			let title = section.querySelector("h2");
			let text = section.querySelector(".text");
			let block_grid = section.querySelector(".block-grid");

			let photo = section.querySelector(".photo");
			let image = photo.querySelector(".photo__image");
			let thumbnail = photo.querySelector(".photo__thumbnail");

			let t1 = gsap.timeline({

			});

			

			ScrollReveal().reveal(section, { 
				delay: 0,
			});

			ScrollReveal().reveal(title, { 
				delay: 200,
				origin: 'left',
				distance: '100%',
			});

			ScrollReveal().reveal(text, { 
				delay: 400,
				scale: 0.1
			});

			ScrollReveal().reveal(block_grid, {
				delay: 600,
				afterReveal: function (el) {
					ScrollReveal().reveal('.acf-block-what-we-do .block-grid > *', {
						interval: 200,
						origin: 'bottom',
						distance: '100%',
					});
				}
			});

			ScrollReveal().reveal(photo, {
				delay: 600,
				afterReveal: function (el) {
					t1.to('.what-we-do-case-studies',{
						"--afterOpacity": 1,
						"--afterScale": 1,
						duration: 0.5,
					}
					);
					ScrollReveal().reveal(image, {
						interval: 200,
						origin: 'right',
						distance: '100%',

						afterReveal: function (el) {
							ScrollReveal().reveal(thumbnail, {
								scale: 0.1
							});
						}
					});

					
				}
			});

		})();


		// Homw Why Us
		(function () {

			let section = document.querySelector(".acf-block-why-us");
			if (section === null) {
				return;
			}

			var circle = CSSRulePlugin.getRule(".why-us:before"); 

			
			let title = section.querySelector("h2");
			let text = section.querySelector(".text");
			

			let gallery = section.querySelector(".gallery");
			let gallery_items = gallery.querySelectorAll(".gallery__item");

			let block_grid = section.querySelector(".block-grid");
			let block_grid_items = block_grid.querySelectorAll(".block-grid__item");

			let t1 = gsap.timeline({

			});

			t1.to('.why-us',{
				"--afterOpacity": 1,
				"--afterScale": 1,
				duration: 0.5,
			}
			);

			ScrollReveal().reveal(section, { 
				delay: 0,
			});

			ScrollReveal().reveal(title, { 
				delay: 200,
				origin: 'left',
				distance: '100%',
			});

			ScrollReveal().reveal(text, { 
				delay: 400,
				scale: 0.1
			});

			ScrollReveal().reveal(gallery, {
				delay: 600,
				afterReveal: function (el) {
					ScrollReveal().reveal(gallery_items, {
						interval: 200,
						scale: 0.1,
					});
				}
			});

			ScrollReveal().reveal(block_grid_items, {
				interval: 200,
				origin: 'bottom',
				distance: '100%',
			});

			

		})();


		// About Hero
		(function () {

			let section = document.querySelector(".acf-block-about-hero");
			if (section === null) {
				return;
			}

			var circle = CSSRulePlugin.getRule(".site-content:before"); 

			
			let h1 = document.querySelector(".acf-block-about-hero h1");
			let mission__title = document.querySelector(".acf-block-about-hero .mission__title");
			let mission__thumbnail = document.querySelector(".acf-block-about-hero .mission__thumbnail");
			let mission__image = document.querySelector(".acf-block-about-hero .mission__image");
			let mission__text = document.querySelector(".acf-block-about-hero .mission__text");

			let story__content = document.querySelector(".acf-block-about-hero .story__content");

			let gallery__item = document.querySelectorAll(".acf-block-about-hero .gallery__item");

			let t1 = gsap.timeline({

			});

			t1.to('.site-content',{
				"--afterOpacity": 1,
				"--afterScale": 1,
				duration: 0.5,
			}
			);

			ScrollReveal().reveal(section, { 
				delay: 0,
			});

			ScrollReveal().reveal(h1, { 
				delay: 200,
				origin: 'left',
				distance: '100%',
			});

			ScrollReveal().reveal(mission__image, { 
				delay: 400,
				scale: 0.1
			});

			ScrollReveal().reveal(mission__thumbnail, { 
				delay: 600,
				scale: 0.1
			});

			ScrollReveal().reveal(mission__title, { 
				delay: 800,
				origin: 'right',
				distance: '100%',
			});

			ScrollReveal().reveal(mission__text, { 
				delay: 800,
				origin: 'bottom',
				distance: '100%',
			});

			ScrollReveal().reveal(story__content, { 
				delay: 1000,
				origin: 'left',
				distance: '100%',
			});

			

			ScrollReveal().reveal('.acf-block-about-hero .gallery', {
				delay: 1200,
				afterReveal: function (el) {
					ScrollReveal().reveal('.acf-block-about-hero .gallery__item', {
						interval: 200,
						scale: 0.1,
					});
				}
			});

		})();

		// History Block

		(function () {

			let section = document.querySelector(".acf-block-history");
			if (section === null) {
				return;
			}

			
			var items = gsap.utils.toArray('.timeline__item');


			ScrollTrigger.matchMedia({
				"screen and (min-width: 1025px)": function () {

					$('.acf-block-history .timeline__item').each(function (index, element) {
						ScrollReveal().reveal('.item__year', {
							delay: 0,
							distance: '100%',
							interval: 400,
							viewOffset: {
								//top: 200,
								bottom: 200
							}
						});

						//console.log($(element).index() % 2 ? 'left' : 'right');

						ScrollReveal().reveal('.item__group', {
							delay: 200,
							distance: '100%',
							interval: 400,
							viewOffset: {
								//top: 200,
								bottom: 200
							}
						});
					});
				
				}
			});

		})();


	},
};
